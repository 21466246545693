@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400,700|Playfair+Display:400,700|Montserrat|Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

html, body {
  font-family: 'Roboto', sans-serif; }

.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100px;
  z-index: 1;
  width: 100%;
  padding: 3rem 2rem; }
  .navbar .navbar-brand {
    position: absolute; }
  .navbar .navbar-toggler {
    position: absolute;
    right: 30px;
    border: none; }
  .navbar .navbar-nav {
    padding: 1rem 1rem 2.5rem 1rem; }
    @media (min-width: 768px) {
      .navbar .navbar-nav {
        padding: 1rem; } }
  .navbar .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"); }
  .navbar .navbar-collapse {
    background: #111111;
    border-top: 1px solid #222222;
    margin-left: -32px;
    margin-right: -32px;
    margin-top: -15px;
    position: absolute;
    top: 115px;
    width: 100%; }
    @media (min-width: 768px) {
      .navbar .navbar-collapse {
        background: transparent;
        border-top: 0;
        top: 30px; } }
  .navbar .bg-light {
    background-color: transparent !important; }
  .navbar .nav-item {
    margin: 0.25rem 0.5rem; }
    @media (min-width: 992px) {
      .navbar .nav-item {
        margin: 0 0.5rem; } }
    .navbar .nav-item a {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-family: 'Lato', sans-serif;
      color: #fff !important;
      font-size: 0.75rem; }
  .navbar .contact a {
    border: 1px solid #fff;
    width: 125px;
    text-align: center; }
    .navbar .contact a:hover {
      background: #fff;
      color: #255a82 !important; }

header {
  height: 100vh;
  position: relative;
  background-image: url(/static/media/blueprint.f264974d.jpg);
  margin-top: -100px; }
  header .content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%); }
    header .content .subline {
      font-size: 0.75rem;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 2px;
      font-family: 'Lato', sans-serif;
      color: #fff; }
      @media (min-width: 992px) {
        header .content .subline {
          font-size: 1.175rem; } }
    header .content .headline {
      margin: 1rem 0;
      font-family: 'Playfair Display', serif;
      font-size: 3rem;
      line-height: 3.75rem;
      color: #fff; }
      @media (min-width: 992px) {
        header .content .headline {
          margin: 1.5rem 0;
          font-size: 4rem;
          line-height: 5rem; } }
    header .content .description {
      font-size: 1.175rem;
      color: #fff; }
    header .content button {
      text-transform: uppercase;
      background-color: #fff;
      color: #255a82;
      border-radius: 0px;
      width: 225px;
      border: 0;
      margin-top: 3rem;
      padding: 0.875rem 0;
      font-size: 0.875rem;
      letter-spacing: 1px;
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      border: 1px solid #fff; }
      header .content button:hover {
        background-color: transparent;
        border: 1px solid #fff;
        color: #fff; }

.services {
  position: relative;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 2rem; }
  @media (min-width: 768px) {
    .services {
      height: 100vh; } }
  @media (min-width: 768px) {
    .services .content {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translateX(-50%) translateY(-50%);
              transform: translateX(-50%) translateY(-50%); } }
  .services .content .headline {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    margin-bottom: 0.5rem; }
  .services .content .subline {
    font-family: 'Playfair Display', serif;
    font-size: 2rem;
    margin-bottom: 4rem; }
  .services .content .title {
    color: #255a82;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    margin-bottom: 0.75rem; }
  .services .content .description {
    margin-bottom: 2rem;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto; }
  .services .content .logo {
    margin-bottom: 1.5rem; }

.portfolio {
  height: 100vh;
  position: relative;
  text-align: center;
  background: #ddd; }
  .portfolio .content {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%); }
    .portfolio .content .headline {
      text-transform: uppercase;
      letter-spacing: 2px;
      font-family: 'Lato', sans-serif;
      font-weight: 600;
      margin-bottom: 0.5rem; }
    .portfolio .content .subline {
      font-family: 'Playfair Display', serif;
      font-size: 1.25rem;
      margin-bottom: 4rem; }
      @media (min-width: 992px) {
        .portfolio .content .subline {
          font-size: 2rem; } }

footer {
  position: absolute;
  border: 0;
  text-align: center;
  border-top: 1px solid #ddd;
  padding: 3rem 0;
  width: 100%; }
  footer .logo {
    margin-bottom: 1rem; }
  footer .copyright {
    margin: 0.5rem 0;
    font-size: 0.5rem;
    color: #999; }
  footer .navbar-nav {
    display: inline-block;
    margin: 0.5rem 0; }
    footer .navbar-nav .nav-item {
      display: inline-block;
      margin: 0 0.5rem;
      font-family: 'Lato', sans-serif; }
      @media (min-width: 992px) {
        footer .navbar-nav .nav-item {
          margin: 0 1.25rem; } }
      footer .navbar-nav .nav-item a {
        color: #255a82;
        font-weight: 600;
        letter-spacing: 1px;
        font-size: 0.75rem;
        text-transform: uppercase; }

.modal .modal-content {
  border-radius: 0px;
  text-align: center; }
  .modal .modal-content .modal-header {
    border-bottom: 0;
    text-align: center; }
    .modal .modal-content .modal-header .close {
      font-family: 'Lato', sans-serif;
      font-weight: 300;
      font-size: 3rem;
      color: #255a82;
      opacity: 1;
      text-shadow: none;
      position: relative;
      top: 5px;
      right: 15px; }

.contact .modal-body {
  padding: 1rem 1rem 2rem 1rem; }
  .contact .modal-body .headline {
    position: relative;
    top: -10px;
    font-family: 'Playfair Display', serif; }
  .contact .modal-body hr {
    width: 40px;
    border-color: #ddd; }
  .contact .modal-body .category {
    margin: 1.25rem 0; }
    .contact .modal-body .category .value {
      font-family: 'Playfair Display', serif;
      font-size: 1.25rem;
      color: #255a82; }
    .contact .modal-body .category .title {
      text-transform: uppercase;
      letter-spacing: 2px;
      font-family: 'Lato', sans-serif;
      font-size: 0.75rem;
      font-weight: 700; }
    .contact .modal-body .category a:hover {
      text-decoration: none; }

.row {
  padding-left: 15px;
  padding-right: 15px; }

