@import 'variables.scss';
@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700|Playfair+Display:400,700|Montserrat|Roboto&display=swap');

html, body {
  font-family: 'Roboto', sans-serif;
}

.navbar {
  position: sticky;
  top: 0;
  height: 100px;
  z-index: 1;
  width: 100%;
  padding: 3rem 2rem;

  .navbar-brand {
    position: absolute;
  }

  .navbar-toggler {
    position: absolute;
    right: 30px;
    border: none;
  }

  .navbar-nav {
    padding: 1rem 1rem 2.5rem 1rem;

    @include breakpoint(small) {
      padding: 1rem;
    }
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }

  .navbar-collapse {
    background: rgb(17, 17, 17);
    border-top: 1px solid rgb(34, 34, 34);
    margin-left: -32px;
    margin-right: -32px;
    margin-top: -15px;
    position: absolute;
    top: 115px;
    width: 100%;

    @include breakpoint(small) {
      background: transparent;
      border-top: 0;
      top: 30px;
    }
  }

  .bg-light {
    background-color: transparent!important;
  }

  .nav-item {
    margin: 0.25rem 0.5rem;

    @include breakpoint(medium) {
      margin: 0 0.5rem;
    }

    a {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-family: 'Lato', sans-serif;
      color: #fff!important;
      font-size: 0.75rem;
    }
  }

  .contact {
    a {
      border: 1px solid #fff;
      width: 125px;
      text-align: center;
      &:hover {
        background: #fff;
        color: #255a82!important;
      }
    }
  }
}

header {
  height: 100vh;
  position: relative;
  background-image: url('./imgs/blueprint.jpg');
  margin-top: -100px;

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    .subline {
      font-size: 0.75rem;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 2px;
      font-family: 'Lato', sans-serif;
      color: #fff;

      @include breakpoint(medium) {
        font-size: 1.175rem;

      }
    }

    .headline {
      margin: 1rem 0;
      font-family: 'Playfair Display', serif;
      font-size: 3rem;
      line-height: 3.75rem;
      color: #fff;

      @include breakpoint(medium) {
        margin: 1.5rem 0;
        font-size: 4rem;
        line-height: 5rem;
      }
    }

    .description {
      font-size: 1.175rem;
      color: #fff;
    }

    button {
      text-transform: uppercase;
      background-color: #fff;
      color: #255a82;
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      border-radius: 0px;
      width: 225px;
      border: 0;
      margin-top: 3rem;
      padding: 0.875rem 0;
      font-size: 0.875rem;
      letter-spacing: 1px;
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      border: 1px solid #fff;

      &:hover {
        background-color: transparent;
        border: 1px solid #fff;
        color: #fff;
      }
    }
  }
}

.services {
  position: relative;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 2rem;

  @include breakpoint(small) {
    height: 100vh;
  }

  .content {
    @include breakpoint(small) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    .headline {
      text-transform: uppercase;
      letter-spacing: 2px;
      font-family: 'Lato', sans-serif;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    .subline {
      font-family: 'Playfair Display', serif;
      font-size: 2rem;
      margin-bottom: 4rem;
    }

    .title {
      color: #255a82;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-family: 'Lato', sans-serif;
      font-weight: 600;
      margin-bottom: 0.75rem;
    }

    .description {
      margin-bottom: 2rem;
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
    }

    .logo {
      margin-bottom: 1.5rem;
    }
  }
}

.portfolio {
  height: 100vh;
  position: relative;
  text-align: center;
  background: #ddd;

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);

    .headline {
      text-transform: uppercase;
      letter-spacing: 2px;
      font-family: 'Lato', sans-serif;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }

    .subline {
      font-family: 'Playfair Display', serif;
      font-size: 1.25rem;
      margin-bottom: 4rem;

      @include breakpoint(medium) {
        font-size: 2rem;
      }
    }
  }
}

footer {
  position: absolute;
  border: 0;
  text-align: center;
  border-top: 1px solid #ddd;
  padding: 3rem 0;
  width: 100%;

  .logo {
    margin-bottom: 1rem;
  }

  .copyright {
    margin: 0.5rem 0;
    font-size: 0.5rem;
    color: #999;
  }

  .navbar-nav {
    display: inline-block;
    margin: 0.5rem 0;

    .nav-item {
      display: inline-block;
      margin: 0 0.5rem;
      font-family: 'Lato', sans-serif;

      @include breakpoint(medium) {
        margin: 0 1.25rem;
      }

      a {
        color: #255a82;
        font-weight: 600;
        letter-spacing: 1px;
        font-size: 0.75rem;
        text-transform: uppercase;
      }
    }
  }
}

.modal {
  .modal-content {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    text-align: center;

    .modal-header {
      border-bottom: 0;
      text-align: center;
      .close {
        font-family: 'Lato', sans-serif;
        font-weight: 300;
        font-size: 3rem;
        color: #255a82;
        opacity: 1;
        text-shadow: none;
        position: relative;
        top: 5px;
        right: 15px;
      }
    }
  }
}

.contact {
  .modal-body {
    padding: 1rem 1rem 2rem 1rem;

    .headline {
      position: relative;
      top: -10px;
      font-family: 'Playfair Display', serif;
    }

    hr {
      width: 40px;
      border-color: #ddd;
    }

    .category {
      margin: 1.25rem 0;
      .value {
        font-family: 'Playfair Display', serif;
        font-size: 1.25rem;
        color: #255a82;
      }

      .title {
        text-transform: uppercase;
        letter-spacing: 2px;
        font-family: 'Lato', sans-serif;
        font-size: 0.75rem;
        font-weight: 700;
      }

      a {
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.row {
  padding-left: 15px;
  padding-right: 15px;
}